import {useState} from 'react';
import {IntegrationScenarioProps} from '../../pages/integrations/sushiswapSample/SushiswapSample';
import {AuthData, useGameAuthData} from '../../services/gameAuth/AuthProvider';

interface IntegrationWrapperProps {
  integrationId: string;
  removeWhitelabel?: boolean;
  element: React.FC<IntegrationScenarioProps>;
}

const IntegrationWrapper: React.FC<IntegrationWrapperProps> = ({
  element,
  integrationId,
  removeWhitelabel = false,
}) => {
  const [isAuthorizationCompleted, setIsAuthorizationCompleted] =
    useState<boolean>(false);

  const [authorization, setAuthorization] = useState<AuthData>();

  useGameAuthData({
    enabled: !isAuthorizationCompleted,
    onSuccess: (val) => {
      if (isAuthorizationCompleted) return;
      setAuthorization(val);
    },
    onSettled: () => {
      console.log('OnSetteled');
      setIsAuthorizationCompleted(true);
    },
  });

  // TODO: Pass load to getAuth middleware
  return (
    <div
      className="w-full h-full aspect-video relative overflow-hidden text-game-base max-h-screen"
      style={{
        backgroundColor: '#000',
        backgroundSize: 'cover',
      }}
    >
      {!removeWhitelabel && (
        <div className="absolute bottom-3 left-3 z-20 text-white text-game-sm">
          Powered by Stemmy
        </div>
      )}

      {element({
        integrationId: integrationId,
        user: authorization,
        authDispatched: isAuthorizationCompleted,
      })}
    </div>
  );
};

export default IntegrationWrapper;
