import {AnimatePresence, motion} from 'framer-motion';
import {useState} from 'react';
import Button from '../../form/button/Button';
import {divVariants} from '../../styles/framerMotionVariants';
import './DialogBubble.scoped.css';

interface DialogBubbleButton {
  id: string;
  title: string;
  onClick: () => void;
}

interface DialogBubbleProps {
  children?: React.ReactNode;
  question: string;
  buttons?: DialogBubbleButton[];
  closeOnClick?: boolean;
}

const DialogBubble: React.FC<DialogBubbleProps> = ({
  children,
  question,
  buttons,
  closeOnClick = false,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="absolute top-[2%] left-1/2 right-1/3"
          initial={'hidden'}
          animate={'visible'}
          transition={{duration: 0.25}}
          variants={divVariants}
          exit={{
            opacity: 0,
            scaleX: ['100%', '120%', '0%'],
          }}
          onClick={closeOnClick ? () => setIsVisible(false) : () => {}}
          {...rest}
        >
          <div className="relative px-8 py-4 rounded-lg bg-[#8444EE] speech-bubble text-white text-game-xs font-sigmarOne font-bold opacity-95">
            {question}
            {buttons && (
              <div className="mt-2 flex justify-center gap-2 items-center">
                {buttons.map((button) => {
                  return (
                    <Button
                      className="w-full"
                      key={button.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        button.onClick();
                      }}
                    >
                      {button.title}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DialogBubble;
