import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {IntegrationGetSaveDAO} from './dao/SaveRequestDAO';

const LoadGameKey = 'integration/save/load';

export interface LoadGameRequest {
  userId?: string;
  integrationId: string;
}

export function useGetLoadGame(
  request: LoadGameRequest,
  options?: UseShortQueryOptions<string, AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
): UseQueryResult<string, AxiosError> {
  return useQuery(
    [LoadGameKey],
    async (): Promise<string> => {
      if (!request.userId) {
        throw Error('No save found');
      }
      const {data} = await APIRequest.post<string>(
        '/Integration/GetSave',
        request,
      );
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 200,
      ...options,
    },
  );
}
