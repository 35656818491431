import {Routes, Route, BrowserRouter} from 'react-router-dom';
import IntegrationWrapper from '../../components/integration/IntegrationWrapper';
import HereIntegrationWrapper from '../../pages/hereIntegration/HereIntegrationWrapper';
import SushiSwap from '../../pages/integrations/sushiswapSample/SushiswapSample';
import Logout from '../../pages/Logout';
import JetpackWrapper from '../../pages/scriptIntegrations/character/jetpack/JetpackWrapper';
import ScriptChestScene from '../../pages/scriptIntegrations/chest/ChestOpenScene';
import SuspenseLayout from '../layouts/basicLayout/SuspenseLayout';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          <Route index element={<HereIntegrationWrapper />} />
          <Route path="/testintegration" element={<HereIntegrationWrapper />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
      </Route>

      <Route path="/integrations">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          {/* Sushiswap */}
          <Route
            path="/integrations/000686F5-AC4C-4607-BA1D-DE9C18E88289"
            element={
              <IntegrationWrapper
                integrationId="000686F5-AC4C-4607-BA1D-DE9C18E88289"
                element={SushiSwap}
              />
            }
          />
        </Route>
      </Route>

      <Route path="/components">
        <Route element={<SuspenseLayout />}>
          <Route path="/components/chest" element={<ScriptChestScene />} />
          <Route path="/components/jetpack" element={<JetpackWrapper />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);
export default AppRouter;
