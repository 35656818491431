import classNames from 'classnames';
import {useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
  useCheckQuest,
  useCompleteFinalTask,
} from '../../../api/hereIntegration/HereIntegration';
import {useGetUserTournament} from '../../../api/Integration/tournament/Tournament';
import {
  useGameAuthData,
  useUpdateGameAuthData,
} from '../../../services/gameAuth/AuthProvider';
import {HereIntegrationSave} from '../../../types/save/HereIntegrationSave';
import Button from '../../form/button/Button';
import Input from '../../form/input/Input';
import {LeaderboardLine} from '../../leaderboard/Leaderboard';
import GamePopup from '../mechanics/InformingSliderView/GamePopup';
import {ButtonsAlignment} from '../mechanics/InformingSliderView/GamePopupElement';
import InformingView from '../mechanics/InformingSliderView/InformingView';
import LeaderboardPopup from '../mechanics/leaderboard/LeaderboardPopup';
import BlackBackground from '../utils/BlackBackground';
import {IntegrationSceneProps} from '../utils/IntegrationScene';
import {UsernameCheckChallenge} from './HerePart1';
import {FailType} from './HerePart2';

const HereTournamentTable: React.FC<
  IntegrationSceneProps<HereIntegrationSave>
> = ({switchScene, prefixId = 'scene_not_set'}) => {
  const [currectStage, setCurrectStage] = useState(-1);
  const {t} = useTranslation(['hereTournamnet', 'universal']);

  const getAuth = useGameAuthData();
  const updateAuth = useUpdateGameAuthData();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<UsernameCheckChallenge>({
    defaultValues: {
      nearUserName: getAuth.data?.near,
    },
  });

  const [isFailedChallange, setIsFailedChallange] = useState<FailType>(
    FailType.None,
  );

  const tournamentTable = useGetUserTournament({
    tournamentId: 'CE2AD246-F4B8-4010-841D-3626B7218392',
    userId: getAuth.data?.id || `unset-${new Date().getTime()}`,
    rows: 6,
  });

  const completeFinalTask = useCompleteFinalTask({
    onSuccess: () => {
      tournamentTable.refetch();
    },
  });

  const checkQuest = useCheckQuest({
    onSuccess: (data) => {
      if (data.detail) {
        setIsFailedChallange(FailType.IncorrectUsername);
        return;
      }

      if (data.balance > 10) {
        completeFinalTask.mutate({
          userId: getAuth.data?.id || `unset-${new Date().getTime()}`,
        });

        updateAuth.mutate({
          isLastCompleted: true,
        });

        setCurrectStage(10);
      } else {
        setIsFailedChallange(FailType.QuestFailed);
      }
    },
    onError: () => setIsFailedChallange(FailType.IncorrectUsername),
  });
  const onCheckQuestSubmit = useCallback(
    (data: UsernameCheckChallenge) => {
      checkQuest.mutate({
        near_account_id: data.nearUserName.toLowerCase(),
      });
    },
    [checkQuest],
  );

  const setStageWithDelay = useCallback((stage: number, delay: number) => {
    setTimeout(() => {
      setCurrectStage(stage);
    }, delay);
  }, []);

  useEffect(() => {
    setStageWithDelay(0, 1000);
  }, [setStageWithDelay]);

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/1.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        {isFailedChallange !== FailType.None && (
          <BlackBackground className="z-20">
            <div className="absolute z-30 w-full h-full flex flex-col justify-center items-center">
              <InformingView>
                <div className="flex flex-col gap-3 my-2">
                  <div className="font-bold text-game-lg">
                    {isFailedChallange === FailType.QuestFailed && (
                      <span>
                        {t('you-should-get-at-least-10-near-on-your-account', {
                          ns: 'universal',
                        })}
                      </span>
                    )}
                    {isFailedChallange === FailType.IncorrectUsername && (
                      <span>
                        {t(
                          'it-looks-like-the-account-with-the-username-you-just-entered-doesnt-exist',
                          {ns: 'universal'},
                        )}
                      </span>
                    )}
                  </div>
                  <Button onClick={() => setIsFailedChallange(FailType.None)}>
                    {t('try-again-1', {ns: 'universal'})}
                  </Button>
                </div>
              </InformingView>
            </div>
          </BlackBackground>
        )}

        {currectStage === 0 && (
          <LeaderboardPopup
            prefixId={prefixId}
            id="leaderboard2"
            leaderboard={{
              title: t('blockchain-tournament'),
              text: t('nice-result'),
              lines:
                tournamentTable.data?.map((x) => {
                  const data: LeaderboardLine = {
                    place: x.position + 1,
                    name: x.name,
                    isHighlightet: x.isSelf,
                    customProperties: [
                      {
                        value: `${x.result}`,
                      },
                    ],
                  };
                  return data;
                }) || [],
            }}
            element={{
              id: 'leaderboard',
              buttonsAlignment: ButtonsAlignment.Horizontal,
              innerButtons: [
                {
                  id: 'again',
                  title: t('try-again'),
                  className: classNames(
                    getAuth.data?.isLastCompleted && 'flex-1',
                  ),
                  onClick: () => {
                    switchScene(5, false);
                  },
                },
                {
                  id: 'increase_score',
                  className: classNames(
                    'flex-1',
                    getAuth.data?.isLastCompleted && 'hidden',
                  ),
                  title: t('increase-your-score'),
                  onClick: () => setCurrectStage(1),
                },
              ],
            }}
          />
        )}

        <GamePopup
          prefixId={prefixId}
          id="quest1"
          active={currectStage == 1}
          elements={[
            {
              id: 'step1',
              conversationImage: '/stemmy/e_head.png',
              title: t('buy-or-earn-10-near'),
              text: t(
                'the-last-task-is-to-buy-or-earn-10-near-to-get-access-to-fast-and-uncontrolled-payments-forever',
              ),
              horizontalElement: (
                <form
                  className="mt-2"
                  onSubmit={handleSubmit(onCheckQuestSubmit)}
                >
                  <div className="blockk">
                    {t('enter-your-near-username', {ns: 'universal'})}
                  </div>
                  <div className="flex w-full mt-2 gap-2">
                    <Input
                      className="flex-1"
                      registration={{register, errors}}
                      name="nearUserName"
                      options={{
                        required: t('enter-your-near-username', {
                          ns: 'universal',
                        }),
                      }}
                    />
                    <Button type="submit">{t('ok-3')}</Button>
                  </div>
                </form>
              ),
              buttonsAlignment: ButtonsAlignment.Horizontal,
              innerButtons: [
                {
                  id: 'back',
                  className: 'mt-4',
                  title: t('back-0'),
                  onClick: () => setCurrectStage(0),
                },
              ],
            },
          ]}
        />

        <GamePopup
          prefixId={prefixId}
          id="fin_quest1"
          active={currectStage == 10}
          onFinish={() => setCurrectStage(0)}
          elements={[
            {
              id: 'fin',
              conversationImage: '/stemmy/e_head.png',
              title: t('wow-you-completed-all-the-quests-congratulations'),
              text: t('i-added-some-points-to-your-tournament-result'),
              buttonsAlignment: ButtonsAlignment.Horizontal,
              innerButtons: [
                {
                  id: 'next',
                  className: 'flex-1 mt-4',
                  title: t('complete'),
                  isFinishOnClick: true,
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default HereTournamentTable;
