import {useCallback, useState} from 'react';
import {useGetLoadGame} from '../../../api/Integration/save/Save';
import {useSaveGame} from '../../../api/Integration/save/SaveMutation';
import {IntegrationUserDAO} from '../../../api/Integration/user/dao/IntegrationUserDAO';
import {IntegrationUserDTO} from '../../../api/Integration/user/dto/IntegrationUserDTO';
import {useCreateNewIntegrationUser} from '../../../api/Integration/user/UserMutations';

import ChestScene from '../../../components/game/scenes/ChestOpenScene';
import IntegrationScene from '../../../components/game/utils/IntegrationScene';
import {AuthData} from '../../../services/gameAuth/AuthProvider';
import {
  HereIntegrationSave,
  IntegrationSave,
  SushiswapSave,
} from '../../../types/save/HereIntegrationSave';

export interface IntegrationScenarioProps {
  integrationId: string;

  authDispatched: boolean;
  user?: AuthData;
}

const SushiSwap: React.FC<IntegrationScenarioProps> = ({
  authDispatched,
  integrationId,
  user: loadedUser,
}) => {
  const [user, setUser] = useState(loadedUser);
  const [currentLoadedData, setCurrentLoadedData] = useState<SushiswapSave>();

  const [scene, setScene] = useState(0);
  useGetLoadGame(
    {
      userId: user?.id,
      integrationId,
    },
    {
      onSuccess: (val) => {
        const loadData = JSON.parse(val) as SushiswapSave;
        setCurrentLoadedData(loadData);
      },
    },
  );

  const saveData = useSaveGame();

  const switchScene = useCallback(
    (
      index: number,
      isSave: boolean = true,
      data?: Partial<IntegrationSave>,
    ) => {
      setScene(index);
      if (isSave) {
        PartialSaver({
          scene: index,
          ...data,
        });
      }
    },
    [],
  );

  const PartialSaver = useCallback((data: Partial<SushiswapSave>) => {
    if (!user) {
      throw Error('User is required to save!');
    }

    const dataToSave: Partial<SushiswapSave> = {
      ...currentLoadedData,
      ...data,
    };

    saveData.mutate({
      userId: user?.id,
      integrationId: integrationId,
      saveData: JSON.stringify(dataToSave),
    });
  }, []);

  const useRegister = useCreateNewIntegrationUser();
  const registerUser = useCallback((userData: IntegrationUserDAO) => {
    useRegister.mutate(userData, {
      onSuccess: (registeredUser) => {
        setUser(registeredUser);
      },
    });
  }, []);

  if (!authDispatched) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="absolute top-0 left-3 z-20 text-white text-game-sm"
        onClick={() => {
          registerUser({name: 'den.mko'});
        }}
      >
        Userid: {user?.id}
      </div>
      {scene === 0 && (
        <IntegrationScene id="chest1" isActive>
          <ChestScene
            registerUser={registerUser}
            switchScene={switchScene}
            save={PartialSaver}
            loadData={currentLoadedData}
            onFinished={() => switchScene(2)}
          />
        </IntegrationScene>
      )}
    </>
  );
};

export default SushiSwap;
