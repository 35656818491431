import {Canvas} from '@react-three/fiber';
import {useCallback, useEffect, useRef, useState} from 'react';
import React from 'react';
import JetpackCharacter, {JetpackCharacterPropsRefProps} from './Jetpack';
import DialogBubble from '../../../../components/game/utils/DialogBubble';

interface ScriptGameCommunicator<T> {
  target: string;
  element: string;

  event: T;
}

interface JetpackGettingEvent {
  event: 'end';
}

interface JetPackSendingEvent {
  event: 'start';
}

const JetpackWrapper: React.FC = () => {
  const characterRef =
    useRef<JetpackCharacterPropsRefProps>() as React.MutableRefObject<JetpackCharacterPropsRefProps>;

  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    const onMessage = (event: {
      data: ScriptGameCommunicator<JetpackGettingEvent>;
    }) => {
      console.log('got a message');
      if (
        event.data.target === 'stemmy-iframe' &&
        event.data.element === 'jetpack'
      ) {
        if (event.data.event.event === 'end') {
          characterRef.current.land();

          //DELETE ME
          setTimeout(() => {
            characterRef.current.dance();
          }, 1000);
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const sendEvent = useCallback((event: JetPackSendingEvent) => {
    const data: ScriptGameCommunicator<JetPackSendingEvent> = {
      target: 'stemmy-script',
      element: 'jetpack',
      event,
    };
    parent.postMessage(data, '*');
  }, []);

  return (
    <div
      className="w-screen h-screen relative"
      onClick={() => {
        if (isStarted) return;
        setIsStarted(true);
        characterRef.current.takeoff();
        sendEvent({
          event: 'start',
        });
      }}
    >
      <Canvas
        shadows
        camera={{rotation: [0, 0, 0], position: [0, 1.15, 5], fov: 28}}
      >
        <ambientLight color="#fff" intensity={0.3} />
        <pointLight color="#fff" position={[0, 5, 5]} />
        <JetpackCharacter ref={characterRef} />
      </Canvas>

      <DialogBubble
        question="Тестовый вопрос или сообщение, уведомление. Кнопки снизу не обязательны"
        buttons={[
          {
            id: 'dsef23rawc5',
            title: 'Ok',
            onClick: () => {},
          },
          {
            id: '23fsfre5',
            title: 'Cancel',
            onClick: () => {},
          },
        ]}
        closeOnClick
      />

      {/* <div className="flex gap-w">
        <Button
          onClick={() => {
            parent.postMessage(
              {
                target: 'stemmy-script',
                element: 'jetpack',
              },
              '*',
            );
          }}
        >
          Test
        </Button>
        <Button
          onClick={() => {
            characterRef.current.speak();
          }}
        >
          Speak
        </Button>
        <Button
          onClick={() => {
            characterRef.current.takeoff();
          }}
        >
          takeoff
        </Button>
        <Button
          onClick={() => {
            characterRef.current.land();
          }}
        >
          land
        </Button>
        <Button
          onClick={() => {
            characterRef.current.dance();
          }}
        >
          dance
        </Button>
      </div> */}
    </div>
  );
};

export default JetpackWrapper;
