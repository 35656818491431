import {Canvas} from '@react-three/fiber';
import {useRef, useState} from 'react';
import {useGLTF} from '@react-three/drei';
import React from 'react';
import Chest, {ChestRefProps} from '../models/Chest';
import Button from '../../form/button/Button';
import StemmyDialogsPage from '../../../pages/dialogs/StemmyDialogsPage';
import {dialogs4} from '../../../pages/dialogs/TestDialogs';
import {StaticSword} from '../../../types/models/StaticSword';
import GameSound from '../effects/Sound';
import {useTranslation} from 'react-i18next';
import {IntegrationSceneProps} from '../utils/IntegrationScene';

interface ConstructorSceneProps extends IntegrationSceneProps<any> {
  onFinished: () => void;
}

const ChestScene: React.FC<ConstructorSceneProps> = ({onFinished}) => {
  const chestRef =
    useRef<ChestRefProps>() as React.MutableRefObject<ChestRefProps>;

  const {t} = useTranslation('universal');

  const {nodes, materials} = useGLTF(
    'https://storage.googleapis.com/stemmy-integrations/models/others/staticweapons/sword.glb',
  ) as unknown as StaticSword;

  const [isNextEnabled, setIsNextEnabled] = useState(false);

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/2.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas
          shadows
          camera={{rotation: [-0.3, 0, 0], position: [0, 2.7, 5], fov: 40}}
        >
          <GameSound
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_normal.mp3"
            autoplay
            loop
          />
          <ambientLight color="#fff" intensity={0.3} />
          <pointLight color="#fff" position={[0, 5, 0]} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />

          <Chest ref={chestRef} onFinished={() => setIsNextEnabled(true)}>
            <mesh
              name="SWORD1"
              geometry={nodes.SWORD1.geometry}
              material={materials.BlueWeapon}
              rotation={[83, Math.PI, Math.PI]}
              scale={1.2}
              position={[0, 1.6, 0]}
            />

            {/* <mesh
              geometry={nodes.Cylinder.geometry}
              material={materials.Coin}
              
            /> */}
          </Chest>

          <mesh
            position={[0, 0, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <boxBufferGeometry attach="geometry" />
            <shadowMaterial
              attach="material"
              color="#000"
              opacity={0.2}
              transparent
            />
          </mesh>
        </Canvas>
      </div>
      <StemmyDialogsPage
        dialogs={dialogs4}
        nextQuestionTimeout={300}
        // onFinished={() => setStageWithDelay(2, 1000)}
      />
      {isNextEnabled && (
        <div className="absolute" style={{bottom: '5%', right: '5%'}}>
          <Button
            className="text-game-xl"
            onClick={() => {
              onFinished();
            }}
          >
            {t('next')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChestScene;
